<template>
  <div class="pieChart"></div>
</template>

<script>
export default {
  name: "linkPie",
  props:{
    data:Object,
  },
  data() {
    return {
      dataSource: this.data,
      myChart: undefined
    }
  },
  watch: {
    data:{
      deep: true, // 深度监听
      handler: function (newVal){
        this.dataSource = newVal
        const option = this.myChart.getOption()
        option.legend.data= this.dataSource.legend
        option.series[0].data = this.dataSource.data
        /*       option.series[1].data = this.dataSource.value
               option.series[0].data = this.setData()*/
        this.myChart.setOption(option)
      }
    }
  },
  methods: {
    setChart() {
      let  option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
      /*  legend: {
          top: '10%',
          textStyle: {
            color: '#3de7c9'
          },
          data: this.dataSource.legend
        },
        grid: {  // top,bottom,left,right等
          left: '3%',
          bottom: 40,
          right: '4%',
          containLabel: true
        },*/
        series: [
      /*    {
            name: '环节数',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '20%'],
            top:'20%',
            align:'top',
            label: {
              position: 'inner',
              color: '#3de7c9',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 5, name: '环节' }
            ]
          },*/
          {
            name: '环节占比',
            type: 'pie',
            top:'20%',
            radius: ['50%', '60%'],
            labelLine: {
              length: 30,
              lineStyle: {
                color: '#3F3F5C'
              }
            },
            /*label: {
              fontSize: 8,
              color: '#00CCFF'
            },*/
           /* labelLine: {
              length: 15,
              length2: 10,
              lineStyle: {
                color: '#3F3F5C'
              }
            },*/
            itemStyle: {
              color: function (params) {
                var colorList = ['#F74F64', '#00CCFF', '#36645D', '#E97040', '#9814FE','#3de7c9','#2775b6','#f2c968'];
                return colorList[params.dataIndex];
              },
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              formatter: '{b}占比{d}%',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              rich: {
                a: {
                  color: '#3de7c9',
                  lineHeight: 22,
                  align: 'center'
          },
                hr: {
                  borderColor: '#8C8D8E',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#4C5058',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#fff',
                  backgroundColor: '#4C5058',
                  padding: [3, 4],
                  borderRadius: 4
                }
            }
            /*  formatter: '{b}：{c}吨\n占比{d}% ',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#3de7c9',
                  lineHeight: 22,
                 /!* align: 'center'*!/
                },
                hr: {
                  borderColor: '#8C8D8E',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#4C5058',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#fff',
                  backgroundColor: '#4C5058',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }*/
            },
        /*    labelLayout: function (params) {
              const isLeft = params.labelRect.x < this.myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                  ? params.labelRect.x
                  : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },*/
            data: this.dataSource.data
          }
        ]
      }
      this.myChart = this.$echarts(this.$el);

      this.myChart.clear();
      this.myChart.resize()
      this.myChart.setOption(option);
    }
  },
  mounted() {
    this.setChart()
  }
}
</script>

<style lang="less" scoped>
.pieChart {
  height: 100%;
  width: 100%;
  padding: 0 20px;
}
</style>
